import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { RoundEntryUnit } from "@roman/shared/types/RoundEntryUnit";
import { useEffect } from "react";
import useSWR from "swr";
import type { Language, LanguageQuery } from "../../types/Language";
import { type AuthHeader, useAuthHeader } from "../useAuthHeader";

interface RoundEntryUnitsResponse {
  roundEntryUnit: RoundEntryUnit;
}

const fetcher = (url: string, headers: AuthHeader, lang: Language) =>
  romanApi.get<RoundEntryUnitsResponse, LanguageQuery>(url, { lang }, { headers });

export const useRoundEntryUnit = (
  roundEntryUnitId: string,
  handleError: (error: RomanApiError) => void,
  lang: Language = "en",
) => {
  const header = useAuthHeader();
  const { data, isLoading, error } = useSWR<RoundEntryUnitsResponse>(
    `/soccer/roundEntryUnits/${roundEntryUnitId}`,
    (url: string) => fetcher(url, header, lang),
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data: data?.roundEntryUnit,
    isLoading,
  };
};
