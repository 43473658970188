import type { RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { Language } from "@roman/shared/types/Language";
import { useMemo } from "react";
import { useRoundEntryUnit } from "./useRoundEntryUnit";
import { useScheduledUserEntries } from "./useScheduledUserEntries";
import { useSoccerRound } from "./useSoccerRound";

export const useSchedule = (
  roundEntryUnitId: string,
  userId: string | undefined,
  handleError: (err: RomanApiError) => void,
  lang: Language = "en",
) => {
  const { data: roundEntryUnit, isLoading: roundEntryUnitLoading } = useRoundEntryUnit(
    roundEntryUnitId,
    handleError,
    lang,
  );
  const { data: roundData, isLoading: roundLoading } = useSoccerRound(roundEntryUnit?.roundId || "", handleError, lang);
  const { data: roundUnitScheduleData, isLoading: scheduleLoading } = useScheduledUserEntries(
    userId,
    roundEntryUnitId,
    handleError,
  );

  const isLoading = roundEntryUnitLoading || roundLoading || scheduleLoading;

  const totalEntries = useMemo(() => {
    return roundUnitScheduleData?.entries.length || 0;
  }, [roundUnitScheduleData?.entries]);

  return {
    roundEntryUnit,
    round: roundData?.round,
    entries: roundUnitScheduleData?.entries || [],
    totalEntries: totalEntries,
    isLoading,
  };
};
